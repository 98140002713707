export const deconstructSlugs = ({ slugs: slugsLocal }) => {
  let schoolId = null;
  let moduleId = null;
  let isBulk: null | boolean = null;

  (slugsLocal || []).forEach((tag: string, index: number) => {
    const nextValue = slugsLocal[index + 1];

    // SchoolID (s)
    if (tag === "s") {
      schoolId = nextValue;
    }
    // ModuleID (m)
    if (tag === "m") {
      moduleId = nextValue;
    }
    // Module variant (v)
    if (tag === "v") {
      if (nextValue === "admin") isBulk = true;
    }
    // Homescreen = a module (v)
    if (tag === "h") {
      isBulk = nextValue;
    }
  });

  return {
    schoolId,
    moduleId,
    isBulk,
  };
};
